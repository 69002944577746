import { useEffect } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { api } from '../../api/api'
import '../../css/phoneInput.css'

const SmsComponent = ({ userDetails, setUserDetails, setLoading, setShowNameCapture }) => {
    const handleChange = (string) => {
        setUserDetails(prevState => ({
            ...prevState,
            contact: string,
            channel: 'sms'
        }))
    }

    useEffect(() => {
        let timeout = setTimeout(async () => {
            if (userDetails?.contact?.length > 10) {
                setLoading(true)
                try {
                    await api.post(`/Verification/exists`, { contact: userDetails?.contact })
                    setShowNameCapture(false)
                    setLoading(false)
                }
                catch (err) {
                    console.error(err)
                    setShowNameCapture(true)
                    setLoading(false)
                }
            }
        }, 750)
        return () => clearTimeout(timeout)
    }, [ userDetails?.contact, setLoading, setShowNameCapture ])
    
    return (
        <PhoneInput
            placeholder="Enter your phone number"
            value={userDetails?.contact}
            onChange={handleChange}
            defaultCountry='US'
            style={{
                margin: '.25rem',
                width: '100%',
                height: 40,
            }}
        />
    );
}

export default SmsComponent;