import { useEffect } from "react"
import { api } from "../../api/api"

const EmailComponent = ({
    userDetails,
    setUserDetails,
    setLoading,
    setShowNameCapture
}) => {

    useEffect(() => {
        let timeout = setTimeout(async () => {
            if (userDetails?.contact?.length > 0 && userDetails?.contact?.includes('@')) {
                setLoading(true)
                try {
                    await api.post(`/Verification/exists`, { contact: userDetails?.contact })
                    setShowNameCapture(false)
                    setLoading(false)
                }
                catch (err) {
                    console.error(err)
                    setShowNameCapture(true)
                    setLoading(false)
                }
            }
        }, 750)
        return () => clearTimeout(timeout)
    }, [userDetails?.contact, setLoading, setShowNameCapture])

    return (
        <input
            type="email"
            placeholder="Enter your email"
            value={userDetails?.contact}
            onChange={(e) =>
                setUserDetails(prevState => ({
                    ...prevState,
                    contact: e.target.value.toLowerCase(),
                    channel: 'email'
                }))
            }
            style={{
                margin: '.25rem',
                width: '100%',
                borderRadius: '1rem',
                padding: '.5rem .5rem .5rem .75rem',
                border: 'none',
                height: 40
            }}
        />
    );
}

export default EmailComponent;