import '../css/spinner.css'

const Spinner = ({ variant, color, style }) => {
    if (variant === null || variant === undefined) {
        return <span className="spinner-light" style={{ color: color, ...style }} />
    }
    if (variant === 'dark') {
        return <span className="spinner-dark" style={{ color: color, ...style }} />
    }
    if (variant === 'full') {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 65px)',
                    ...style
                }}
            >
                <span className="spinner-light" style={{ color: color }} />
            </div>
        )
    }
}

export default Spinner