import SmsComponent from "./SmsComponent"
import EmailComponent from "./EmailComponent"

const ContactComponent = ({
    smsSignupMethod,
    userDetails,
    setUserDetails,
    setShowNameCapture,
    setLoading
}) => {
    return (
        smsSignupMethod ?
        <SmsComponent
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            setShowNameCapture={setShowNameCapture}
            setLoading={setLoading}
        /> 
        :
        <EmailComponent
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            setShowNameCapture={setShowNameCapture}
            setLoading={setLoading}
        /> 
    )
}

export default ContactComponent