import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth';

const PrivateRoutes = () => {
    const { authenticated } = useAuth()
    const location = useLocation()

    return (
        authenticated ? <Outlet /> : <Navigate to="/" state={{ from: location }} />
    )
}

export default PrivateRoutes