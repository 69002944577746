const Card = ({ children }) => {
    return (
        <div
            style={{
                textAlign: 'center',
                width: '22rem',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '0',
                wordWrap: 'break-word',
                backgroundColor: '#3a3a3a',
                backgroundClip: 'border-box',
                borderRadius: '1rem',
                justifyContent: 'space-evenly',
                minHeight: '1px',
                padding: '1.25rem',
                margin: 'auto',
                color: '#ededed',
                boxShadow: '0 0.5rem 1rem rgb(0 0 0 / .25%) !important'
            }}
        >
            { children }
        </div>
    )
}

export default Card;