import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Edit from '@mui/icons-material/Edit';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import Shield from '@mui/icons-material/Shield';

import Spinner from '../Spinner'

import AuthCodeEntry from './AuthCodeEntry';
import Card from './Card';
import { useLogin } from '../../hooks/useLogin';

const AuthModal = ({
    setShowModal,
    userDetails,
    setUserDetails,
    redirect
}) => {
    const { mutateAsync, isLoading, isSuccess, isError } = useLogin();
    const [buttonActive, setButtonActive] = useState(false)

    const navigate = useNavigate()

    const runMutation = async () => {
        try {
            await mutateAsync(userDetails);
            navigate(redirect, { replace: true });
        } catch (err) {
            setUserDetails(prev => ({ ...prev, code: '' }))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await runMutation()
    }

    const pressSubmitHandler = (e) => {
        if (userDetails?.code?.length !== 6 || isLoading || isError) return
        if (e.key === 'Return' || e.key === 'Enter') {
            handleSubmit(e)
        }
    }

    const handleCodeChange = async (code) => {
        setUserDetails(prev => ({ ...prev, code: code }))
        if (isLoading) return;
        if (code.length === 6) {
            await mutateAsync({ ...userDetails, code: code })
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                top: -2,
                left: 0,
                right: 0,
                bottom: -1,
                backgroundColor: '#ededed',
                zIndex: 10000,
                overflow: 'scroll',
                opacity: 1,
                transition: 'opacity .5s ease-in-out'
            }}
        >
            <Card>
                <div
                    style={{
                        margin: 35
                    }}
                >
                    {
                        !isSuccess
                            ? (
                                <Shield
                                    // fontSize='large'
                                    style={{
                                        fontSize: '8rem',
                                        // margin: 'auto',
                                        color: '#2dd67d'
                                    }}
                                />
                            ) : (
                                <VerifiedUser
                                    style={{
                                        fontSize: '8rem',
                                        // margin: 'auto',
                                        color: '#2dd67d'
                                    }}
                                />
                            )
                    }
                </div>
                <h1>{userDetails?.channel === 'sms' ? 'Check your phone.' : 'Check your email.'}</h1>
                <span style={{ margin: '1rem' }}>A 6-digit code has been sent to <strong>{userDetails?.contact}</strong>
                    {<Edit
                        fontSize='small'
                        onClick={() => setShowModal(false)}
                        style={{
                            cursor: 'pointer',
                            margin: '0 0 0 .65rem',
                            color: '#2dd67d'
                        }}
                    />}
                </span>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            margin: '.5rem 0',
                        }}
                    >
                        {
                            <AuthCodeEntry
                                onChange={handleCodeChange}
                                style={{
                                    width: '2.5rem',
                                    height: '2.5rem',
                                    margin: '.25rem',
                                    borderRadius: '.5rem',
                                    textAlign: 'center',
                                    fontSize: '1.25rem',
                                    border: isError && '1px solid red'
                                }}
                            />
                        }
                    </div>
                    <button
                        type='submit'
                        onKeyDown={pressSubmitHandler}
                        onTouchStart={() => userDetails?.code !== '' && !isLoading ? setButtonActive(true) : null}
                        onTouchEnd={() => userDetails?.code !== '' && !isLoading ? setButtonActive(false) : null}
                        disabled={userDetails?.code === '' || isLoading}
                        style={{
                            width: '100%',
                            height: 40,
                            border: 'none',
                            boxShadow: buttonActive ? 'none' : '0 0.5rem 1rem rgb(0 0 0 / .25%)',
                            borderRadius: '1rem',
                            backgroundColor: buttonActive ? '#2ab769' : '#2dd67d',
                            color: '#ededed',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: 400,
                            margin: '1rem 0 0 0',
                        }}
                    >
                        {isLoading ? <Spinner variant='dark' /> : 'VERIFY'}
                    </button>
                </form>
            </Card>
        </div>
    );
};

export default AuthModal;