import AuthCard from '../components/authentication/AuthCard';
import { useLocation, Navigate } from 'react-router-dom';
import AppLoading from '../components/AppLoading';
import { useAuth } from '../hooks/useAuth';

const Authentication = () => {
    const location = useLocation() 
    const from = (location.state?.from?.pathname.includes('preferences') || location.state?.from?.pathname == null) ? "home" : location.state?.from?.pathname;

    const { authenticated, isLoading } = useAuth();

    if (isLoading) return <AppLoading />

    if (authenticated) return <Navigate to={from} replace={true} />

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '101vh',
                width: '100vw',
                backgroundColor: '#ededed'
            }}
        >
            <AuthCard redirect={from} />
        </div>
    );
};

export default Authentication;