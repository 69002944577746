// import { Close } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ children, onClose, style }) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}
        >
            <div
                style={{
                    border: 'none',
                    backgroundColor: '#ededed',
                    padding: '1rem',
                    borderRadius: '1rem',
                    width: '90%',
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    ...style
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CloseIcon onClick={onClose} style={{ color: '#3a3a3a' }}/>
                </div>
                { children }
            </div>
        </div>
    )
}

export default Modal;