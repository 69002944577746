import p from '../assets/logo_p_gradient.svg'
import Spinner from './Spinner'

const AppLoading = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh'
            }}
        >
            <img src={p} alt='PARennial Golf' style={{ marginBottom: '2.5rem', width: '15%' }} />
            <Spinner />
        </div>
    )
}

export default AppLoading