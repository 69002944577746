import { useMutation, useQueryClient } from "react-query";
import { api } from "../api/api";

export const useLogin = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (credentials) => {
            const { data } = await api.post('/Auth/confirm', credentials);
            return data;
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData("user-details", data);
            }
        }
    );
};