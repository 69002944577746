import { useState } from 'react';
// import { AuthContext } from '../../context/AuthContext';
import logoP from '../../assets/logo_p_gradient.svg';
import AuthModal from './AuthModal';
import Spinner from '../Spinner';
import { api } from '../../api/api';
import Card from './Card';
import ContactComponent from './ContactComponent';
import ChangeSignInContactMethodButton from './ChangeSignInContactMethodButton';

const AuthCard = ({ redirect }) => {
    const [ userDetails, setUserDetails ] = useState(() => {
        let details = {
            contact: '',
            channel: '',
            firstName: '',
            lastName: '',
            code: ''
        }
        return details
    })

    const [ showModal, setShowModal ] = useState(false)
    const [ showNameCapture, setShowNameCapture ] = useState(false)
    const [ smsSignupMethod, setsmsSignupMethod ] = useState(false)
    const [ buttonActive, setButtonActive ] = useState(false)
    const [ nameError, setNameError ] = useState({
        firstName: false,
        lastName: false
    })
    const [ loading, setLoading ] = useState(false)

    redirect = redirect ?? '/home'

    const logInOrSignUp = async (e) => {
        e.preventDefault()
        if (showNameCapture) {
            setNameError({ 
                ...nameError, 
                lastName: userDetails.lastName.length === 0 ? true : false,
                firstName: userDetails.firstName.length === 0 ? true : false 
            })
            if (userDetails.firstName.length === 0 || userDetails.lastName.length === 0) {
                return
            } else {
                setNameError({
                    firstName: false,
                    lastName: false
                })
            }
        }
        if (userDetails?.contact?.length > 0) {
            setLoading(true)
            try {
                const data = await api.post('/Auth/request',
                    JSON.stringify(userDetails),
                )
            if (data.status === 200) {
                setLoading(false)
                setShowModal(true)
            }
            } catch (err) {
                setLoading(false)
                console.log(err)
            }
        }
    }

    const onChannelChange = () => {
        setsmsSignupMethod(!smsSignupMethod)
        setUserDetails({
            contact: '',
            channel: '',
            firstName: '',
            lastName: '',
            code: ''
        })
        setShowNameCapture(false)
        setNameError({
            firstName: false, 
            lastName: false
        })
    }
    
    return (
        <Card>
        {
            showModal ?
            <AuthModal
                setShowModal={setShowModal} 
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                redirect={redirect}
            />
            :
            <>
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '2rem'
                    }}
                >
                    <img 
                        src={logoP} 
                        alt='PARennial Golf' 
                        style={{
                            width: '3rem',
                            margin: 'auto',
                        }} 
                    />
                    <h4
                        style={{
                            textAlign: 'center',
                            padding: '2rem 0 2rem 0',
                            fontSize: 38,
                            fontWeight: 300,
                            margin: 0
                        }}
                    >
                        Welcome
                    </h4>
                </div>
                <form
                    onSubmit={logInOrSignUp}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '0 0 .5rem 0'
                    }}
                >
                    {showNameCapture && 
                        <p style={{ alignSelf: 'flex-start' }}>Create your account to get started.</p>}
                    {(smsSignupMethod && showNameCapture) &&
                        <p style={{ textAlign: 'left' }}><strong style={{ color: '#2dd67d' }}>ATTENTION:</strong> If you already have an email account, login there and add your phone number in preferences.</p>}
                    <ContactComponent 
                        userDetails={userDetails}
                        setUserDetails={setUserDetails}
                        smsSignupMethod={smsSignupMethod}
                        showNameCapture={showNameCapture}
                        setShowNameCapture={setShowNameCapture}
                        setLoading={setLoading}
                    />
                    <input 
                        type="text"
                        placeholder='First name'
                        value={userDetails?.firstName}
                        onChange={e => setUserDetails(prevState => ({...prevState, firstName: e.target.value}))}
                        style={{ 
                            display: showNameCapture ? 'block' : 'none', 
                            margin: '.25rem',
                            border: `1px solid ${nameError.firstName ? 'red' : '#ededed'}`,
                            padding: '.5rem .5rem .5rem .75rem'
                        }} 
                    />
                    <input 
                        type="text" 
                        placeholder='Last name'
                        value={userDetails?.lastName}
                        onChange={e => setUserDetails(prevState => ({...prevState, lastName: e.target.value}))}
                        style={{ 
                            display: showNameCapture ? 'block' : 'none', 
                            margin: '.25rem',
                            border: `1px solid ${nameError.lastName ? 'red' : '#ededed'}`,
                            padding: '.5rem .5rem .5rem .75rem',
                        }} 
                    />
                    <button
                        type='submit'
                        onTouchStart={() => userDetails?.contact?.length > 0 && !loading ? setButtonActive(0) : null}
                        onTouchEnd={() => setButtonActive(null)}
                        disabled={loading}
                        style={{
                            border: 'none',
                            borderRadius: '1rem',
                            backgroundColor: buttonActive === 0 ? '#2ab769' : '#2dd67d',
                            color: '#ededed',
                            width: '100%',
                            padding: '.5rem',
                            letterSpacing: '.5px',
                            alignSelf: 'center',
                            margin: '.25rem',
                            boxShadow: buttonActive === 0 ? 'none' : '0 0.5rem 1rem rgb(0 0 0 / 25%)',
                            fontWeight: 500,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: '2.6rem',
                        }}
                    >
                        { loading ? <Spinner variant='dark' /> : 'Log In / Sign Up' }
                    </button>
                    <ChangeSignInContactMethodButton
                        smsSignupMethod={smsSignupMethod}
                        onChannelChange={onChannelChange}
                    />
                </form>
            </>
        }
        </Card>
    )
}

export default AuthCard