import { useLocation, useNavigate } from 'react-router-dom';
import { 
    HomeSharp,
    HomeOutlined,
    FactoryOutlined,
    FactorySharp,
    CalendarMonthSharp,
    CalendarMonthOutlined,
    Person,
    PersonOutlined
} from '@mui/icons-material';

import { motion } from 'framer-motion';

const Navbar = () => {
   
    const navigate = useNavigate();
    const location = useLocation()

    const textDetail = {
        fontSize: '1.5vw',
        // padding: '.1rem',
        // position: 'absolute',
        // bottom: '20%'
    }
    
    const pgCol = {
        // width: '100%',
        // maxWidth: '25%',
        position: 'relative',
        // flex: '0 0 25%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#2dd67d',
    }

    const tab = [
        {
            id: 0,
            name: 'HOME',
            path: '/home',
            style: pgCol,
            materialIconActive: <HomeSharp fontSize='medium' />,
            materialIconInactive: <HomeOutlined fontSize='medium' />,
        },
        {
            id: 1,
            name: 'FACILITIES',
            path: '/facilities',
            style: pgCol,
            materialIconActive: <FactorySharp fontSize='medium' />,
            materialIconInactive: <FactoryOutlined fontSize='medium' />,
        },
        {
            id: 2,
            name: 'BOOKING',
            path: '/booking',
            style: pgCol,
            materialIconActive: <CalendarMonthSharp fontSize='medium' />,
            materialIconInactive: <CalendarMonthOutlined fontSize='medium' />,
        },
        {
            id: 3,
            name: 'PERSONAL',
            path: '/personal',
            style: pgCol,
            materialIconActive: <Person fontSize='medium' />,
            materialIconInactive: <PersonOutlined fontSize='medium' />,
        }
    ]
    
    const animateOut = location.pathname.includes('/cart') || location.pathname.includes('/sessions') || location.pathname.includes('/preferences')
    return (
        <motion.nav
            key='nav-bar'
            // transition={{ duration: .75 }}
            initial={{ y: animateOut ? 0 : 65, transition: { duration: .3 } }}
            animate={{ y: animateOut ? 65 : 0, transition: { duration: .3 } }}
            exit={{ y: animateOut ? 0 : 65, transition: { duration: .3 } }}
            style={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                zIndex: 1000000000000,
                backgroundColor: '#ededed',
                color: '#3a3a3a',
                height: 65,
                letterSpacing: '1px',
                display: 'flex',
                boxShadow: location.pathname.includes('/home') ? 'none' : '0 0.5rem 1rem rgb(0 0 0 / 25%)',
                justifyContent: 'space-around'
            }}
        >
            {tab.map((item, i) => (
                <motion.div
                    whileTap={{ scale: 1.15 }}
                    whileHover={{ scale: 1.15 }}
                    whileFocus={{ scale: 1.15 }}
                    key={i}
                    style={item.style}
                    onClick={() => navigate(item.path, { state: { from: location.pathname } })}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {location.pathname.includes(item.path) ? item.materialIconActive : item.materialIconInactive}
                        <span 
                            style={{...textDetail, fontWeight: location.pathname.includes(item.path) ? 'bold' : 'normal'}}
                            >
                            {item.name}
                        </span>
                    </div>
                </motion.div>
            ))}
        </motion.nav>
    )
}

export default Navbar