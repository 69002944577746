import {
    useState,
    Suspense,
    lazy
} from 'react'

import {
    useLocation,
    Navigate,
    Routes,
    Route,
} from "react-router-dom";

// screens
import Authentication from './screens/Authentication';

import Navbar from './navbar/Navbar';

import {
    DateTime
} from 'luxon'

// components
import PrivateRoutes from './components/PrivateRoutes';

import Spinner from './components/Spinner';
import NoPhoneModal from './components/NoPhoneModal';

// hooks
import { useAuth } from './hooks/useAuth';
import { useGetAppData } from './hooks/useGetAppData';
import { useLocalStorage } from './hooks/useLocalStorage';

import AppLoading from './components/AppLoading';
import { AnimatePresence } from 'framer-motion';
import { useThemeColor } from './hooks/useThemeColor';

const Home = lazy(() => import('./screens/Home'));
const SessionManagement = lazy(() => import('./screens/SessionManagement'));
const Booking = lazy(() => import('./screens/Booking'));
const ShoppingCart = lazy(() => import('./screens/ShoppingCart'));
const Facility = lazy(() => import('./screens/Facility'));
const Personal = lazy(() => import('./screens/Personal'));
const Preferences = lazy(() => import('./screens/Preferences'));
const Membership = lazy(() => import('./screens/MembershipScreen'));
const PaymentMethod = lazy(() => import('./screens/PaymentMethodScreen'));

// let date = () => DateTime.now().toUTC() >= DateTime.now().startOf('day').toUTC().plus({ hours: 22, minutes: 30 }) ? DateTime.now().startOf('day').toUTC().plus({ days: 1 }) : DateTime.now().startOf('day').toUTC()
export default function App() {
    const [date, setDate] = useState(() => (DateTime.now().setZone('America/Chicago').toUTC() >= DateTime.now().setZone('America/Chicago').startOf('day').toUTC().plus({ hours: 22, minutes: 30 }))
        ? DateTime.now().setZone('America/Chicago').startOf('day').toUTC().plus({ days: 1 })
        : DateTime.now().setZone('America/Chicago').startOf('day').toUTC())

    const [selectedBookings, setSelectedBookings] = useLocalStorage('selectedBookings', JSON.parse(localStorage.getItem('selectedBookings')) || [])
    const [settingsModal, setSettingsModal] = useState(() => {
        let settings = JSON.parse(localStorage.getItem('appSettings'))
        if (settings) return settings
        settings = {
            showContactModal: true,
            ignore: false
        }
        localStorage.setItem('appSettings', JSON.stringify(settings))
        return settings
    })

    const location = useLocation()

    useThemeColor()

    const { data: userDetails } = useAuth(data => {
        console.log('userDetails')
        setSettingsModal(prev => {
            let update = {
                showContactModal: data?.phoneNumber === null || data?.email === null ? true : prev?.showPhoneModal,
                ignore: prev?.ignore ?? false
            }
            localStorage.setItem('appSettings', JSON.stringify(update))
            return update
        })
    })

    const results = useGetAppData()
    if (results?.some(r => r.isLoading)) return <AppLoading />
    const facilityStatus = results[0]?.data
    const facilityBookings = results[1]?.data
    const userBookingData = results[2]?.data

    return (
        <div>
            {
                (settingsModal?.showContactModal && !settingsModal?.ignore && location.pathname.includes('/home'))
                && <NoPhoneModal
                    userDetails={userDetails}
                    setSettingsModal={setSettingsModal}
                    onClose={
                        () => {
                            let settings = { showContactModal: false, ignore: true }
                            localStorage.setItem("appSettings", JSON.stringify(settings));
                            setSettingsModal(settings)
                        }
                    }
                />
            }
            <Suspense fallback={<Spinner variant='full' />}>
                <AnimatePresence mode='wait'>
                    <Routes location={location} key={location.pathname}>
                        <Route element={<PrivateRoutes />}>
                            <Route
                                path='home'
                                element={
                                    <Home
                                        userDetails={userDetails}
                                        facilityStatus={facilityStatus}
                                    />
                                }
                            />
                            <Route
                                key='session-management'
                                path='sessions'
                                element={
                                    facilityStatus?.terminalManagementActive
                                        ? <SessionManagement
                                            userDetails={userDetails}
                                            userBookingData={userBookingData}
                                            currentSession={userBookingData}
                                        />
                                        : <Navigate to='/home' />
                                }
                            />
                            <Route
                                path='facilities'
                                element={
                                    <Facility
                                        facilityBookings={facilityBookings}
                                    />
                                }
                            />
                            <Route
                                path='booking'
                                element={
                                    <Booking
                                        date={date}
                                        setDate={setDate}
                                        selectedBookings={selectedBookings}
                                        setSelectedBookings={setSelectedBookings}
                                    />
                                }
                            />

                            <Route
                                path='cart'
                                element={
                                    <ShoppingCart
                                        selectedBookings={selectedBookings}
                                        setSelectedBookings={setSelectedBookings}
                                    />
                                }
                            />
                            <Route
                                path='personal'
                                element={
                                    <Personal />
                                }
                            />
                            <Route
                                path='preferences'
                                element={
                                    <Preferences />
                                }
                            />
                            <Route
                                path='membership'
                                element={
                                    <Membership />
                                }
                            />
                            <Route
                                path='payment-methods'
                                element={
                                    <PaymentMethod />
                                }
                            />
                        </Route>
                        <Route index path="/" element={<Authentication />} exact />
                        <Route
                            path='*'
                            element={
                                <span style={{ padding: 20 }}>
                                    404 NOT FOUND
                                </span>
                            }
                        />
                    </Routes>
                </AnimatePresence>
            </Suspense>
            {
                (!location.pathname.includes('/home') &&
                    !location.pathname.includes('/facilities') &&
                    !location.pathname.includes('/booking') &&
                    !location.pathname.includes('/personal') &&
                    !location.pathname.includes('/preferences') &&
                    !location.pathname.includes('/cart') &&
                    !location.pathname.includes('/sessions')) ? null :
                    <Navbar />
            }
        </div>
    )
}