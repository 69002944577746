import { useState } from 'react'

import Email from '@mui/icons-material/Email';
import PhoneIphone from '@mui/icons-material/PhoneIphone';


const ChangeSignInContactMethodButton = ({ 
    smsSignupMethod, 
    onChannelChange 
}) => {
    const [ buttonActive, setButtonActive ] = useState(false)

    return (
            <>
                <span
                    style={{
                        fontSize: 12,
                        fontWeight: 600,
                        letterSpacing: '.5px',
                        margin: '1rem'
                    }}
                >
                    OR
                </span>
                <button
                    onTouchStart={() => setButtonActive(true)}
                    onTouchEnd={() => setButtonActive(false)}
                    onClick={onChannelChange}
                    style={{
                        border: '1px solid #2dd67d',
                        borderRadius: '1rem',
                        backgroundColor: '#3a3a3a',
                        color: '#ededed',
                        width: '100%',
                        padding: '.5rem',
                        letterSpacing: '.5px',
                        alignSelf: 'center',
                        boxShadow: buttonActive === 1 ? 'none' : '0 0.5rem 1rem rgb(0 0 0 / 25%)',
                        fontWeight: 500,
                        display: 'flex',
                        height: 40,
                    }}
                >
                    {
                        smsSignupMethod ?
                        <Email
                            fontSize='small'
                            style={{
                                margin: 'auto 0 auto 0',
                                width: '33.33%'
                            }}
                        />
                        :
                        <PhoneIphone
                            fontSize='small'
                            style={{
                                margin: 'auto 0 auto 0',
                                width: '33.33%'
                            }}
                        />
                    }
                    <span
                        style={{
                            margin: 'auto 0 auto 0',
                            width: '33.33%'
                        }}
                    >
                        { smsSignupMethod ? 'EMAIL' : 'SMS' }
                    </span> 
                    <span style={{ width: '33.33%' }}/>
                </button>
            </>
    )
}

export default ChangeSignInContactMethodButton