import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useThemeColor = () => {
    const location = useLocation()

    useEffect(() => {
        const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
        const originalThemeColor = themeColorMetaTag.getAttribute('content');
        let newThemeColor = '#2dd67d'; // Change this to your desired theme color
        if (location.pathname.includes('/sessions') || location.pathname.includes('cart')) {
            newThemeColor = '#3a3a3a';
        }
        if (location.pathname.includes('/booking') || location.pathname.includes('/personal')
            || location.pathname.includes('/preferences') || location.pathname.includes('/membership')
            || location.pathname.includes('/payment-method')) {
            newThemeColor = '#ededed';
        }

        themeColorMetaTag.setAttribute('content', newThemeColor);
        return () => {
            themeColorMetaTag.setAttribute('content', originalThemeColor); // Reset the theme color when the component unmounts
        };
    }, [location.pathname]);
}