import { useNavigate } from "react-router-dom";
import Button from "./buttons/Button";
import Modal from "./Modal";

const NoPhoneModal = ({ userDetails, onClose }) => {
    const navigate = useNavigate();

    const noPhoneContent = (
        <div style={{ fontSize: 14 }}>
            <p>Let's get your <strong>phone number</strong> verified so you can <strong>get login codes via text</strong>.</p>
            <p>Created a separate account with your phone number? You can reach out to <a href='sms:+12318388356'>support</a> and we will be happy to fix this for you!</p>
        </div>
    )

    const noEmailContent = (
        <div style={{ fontSize: 14 }}>
            <p>Let's get your <strong>email</strong> verified so we can <strong>automatically log you into your Trackman account</strong> when you begin a booking or session.</p>
            <p>Created a separate account with your email address? You can reach out to <a href='sms:+12318388356'>support</a> and we will be happy to fix this for you!</p>
        </div>
    )

    return (
        <Modal
            onClose={onClose}
            style={{
                height: 'fit-content',
            }}
        >
            <div>
                <h1>Hey { userDetails?.firstName }!</h1>
                <p style={{ fontSize: 14 }}>Welcome to the PARennial Golf web app!</p>
                { userDetails?.phoneNumber === null && noPhoneContent }
                { userDetails?.email === null &&  noEmailContent}
                <Button
                    onClick={() => navigate('/preferences')}
                    style={{
                        width: '100%',
                        fontSize: 20,
                    }}
                >
                    Continue
                </Button>
            </div>
        </Modal>
    )
}

export default NoPhoneModal;