import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import './css/index.css';
import App from './App';
import { mobileCheck } from './services/utility';
import 'bootstrap/dist/css/bootstrap.min.css';

import MobileDeviceRequired from './components/MobileDeviceRequired';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js"
import { DownloadFromAppStore } from './screens/DownloadFromAppStore';
// import { UserLocationProvider } from './context/UserLocationContext';

const promise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const pgFont = [{ cssSrc: 'https://use.typekit.net/pwi5rch.css' }];

const root = ReactDOM.createRoot(document.getElementById('root'));

let check = mobileCheck()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
})


const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone/.test(userAgent);
};

console.log(isIOS())

if (isIOS() || !check) {
  root.render(
    <DownloadFromAppStore />
  )
} else {
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        {
          !check ? <MobileDeviceRequired /> :
            <Elements stripe={promise} options={{ fonts: pgFont }}>
              <App />
            </Elements>
        }
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);
}