import axios from 'axios';

axios.defaults.withCredentials = true;

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_AZURE_BASE,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const api_V2 = axios.create({
    baseURL: process.env.REACT_APP_API_AZURE_BASE_V2,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

let isRefreshing = false;
const pendingRequests = [];


function getCookie(name) {
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name + '=') === 0) {
            return cookie.substring((name + '=').length, cookie.length);
        }
    }
    return null;
}

function invokeLogoutEvent() {
    const event = new CustomEvent('logout-event', { detail: { message: 'logout event fired' } });
    window.dispatchEvent(event);
}

const addPendingRequest = (config, resolve, reject) => {
    pendingRequests.push({ config, resolve, reject });
};

async function processPendingRequests() {
    if (pendingRequests.length === 0) return;
    const { config, resolve, reject } = pendingRequests.shift();
    try {
        const response = await api(config);
        resolve(response);
        processPendingRequests();
    } catch (error) {
        if (error.response.status === 401) {
            pendingRequests.forEach(({ reject }) => reject(error));
            pendingRequests.length = 0;
        } else {
            reject(error);
            processPendingRequests();
        }
    }
}

api.interceptors.request.use(async (config) => {
    const cookie = getCookie('X-Token-Expiration');
    const tokenExpiration = parseInt(cookie, 10);

    if (isRefreshing && config.url !== '/Auth/refresh') {
        new Promise((resolve, reject) => {
            addPendingRequest(config, resolve, reject);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    if (tokenExpiration && tokenExpiration < 30 && !isRefreshing) {
        isRefreshing = true;
        try {
            await api.post('/Auth/refresh') // Use your refresh token endpoint
            await processPendingRequests();
        } catch (err) {
            return Promise.reject(err);
        } finally {
            isRefreshing = false;
        }
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error?.response?.status === 401 && !error?.config?._retry) {
            if (error?.response?.data?.error === "No refresh") {
                invokeLogoutEvent();
                return Promise.reject(error);
            }

            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    addPendingRequest(error.config, resolve, reject);
                }).catch(err => {
                    invokeLogoutEvent();
                    return Promise.reject(err);
                });
            } else {
                isRefreshing = true;
                try {
                    await api.post('/Auth/refresh'); // Use your refresh token endpoint
                    await processPendingRequests();
                } catch (refreshError) {
                    refreshError.isAuthError = true;

                    pendingRequests.forEach(({ reject }) => reject(refreshError));
                    pendingRequests.length = 0;

                    invokeLogoutEvent();

                    return Promise.reject(refreshError);
                } finally {
                    isRefreshing = false;
                }
            }
        }

        return Promise.reject(error);
    }
);

api_V2.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error?.response?.status === 401 && !error?.config?._retry) {
            if (error?.response?.data?.error === "No refresh") {
                invokeLogoutEvent();
                return Promise.reject(error);
            }

            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    addPendingRequest(error.config, resolve, reject);
                }).catch(err => {
                    invokeLogoutEvent();
                    return Promise.reject(err);
                });
            } else {
                isRefreshing = true;
                try {
                    await api.post('/Auth/refresh'); // Use your refresh token endpoint
                    await processPendingRequests();
                } catch (refreshError) {
                    refreshError.isAuthError = true;

                    pendingRequests.forEach(({ reject }) => reject(refreshError));
                    pendingRequests.length = 0;

                    invokeLogoutEvent();

                    return Promise.reject(refreshError);
                } finally {
                    isRefreshing = false;
                }
            }
        }

        return Promise.reject(error);
    }
);
