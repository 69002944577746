import React from 'react'
import logoLight from '../assets/logo_p_gradient.svg'
import { useNavigate } from 'react-router-dom'

const MobileDeviceRequired = () => {

    const navigate = useNavigate()

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#3a3a3a'
        }}
        >
            <img 
                src={logoLight} 
                alt='PARennial Golf' 
                style={{
                    maxWidth: '300px', 
                    marginBottom: 30,
                    cursor: 'pointer'
                }} 
                onClick={() => navigate('/')}
            />
            <span style={{
                color: '#ededed',
                fontSize: 18,
                letterSpacing: '.25px'
            }}
            >
                A mobile device is required to view the web app.
            </span>
            <span style={{
                color: '#ededed',
                letterSpacing: '.5px',
            }}
            >
                For help, please contact <a href="sms:+12318388356" style={{color:'#2dd67d'}}>support</a>.
            </span>
        </div>
    )
}

export default MobileDeviceRequired