import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { api } from "../api/api";

export const useAuth = () => {

    const queryClient = useQueryClient();

    useEffect(() => {
        const handleCustomEvent = () => {
            queryClient.invalidateQueries("user-details");
        };

        window.addEventListener('logout-event', handleCustomEvent);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('logout-event', handleCustomEvent);
        };
    }, [queryClient]);

    const { data, isLoading, isSuccess: authenticated } = useQuery(
        ["user-details"],
        async () => {
            const { data } = await api.get("/Users");
            return data;

        },
        {
            retry: 0,
            refetchOnMount: true,
            staleTime: 1000 * 60,
        }
    );
    return { data, isLoading, authenticated };
};
