import {
    useQueries,
} from "react-query";
import { api } from '../api/api'
import { DateTime } from "luxon";
import { useAuth } from "./useAuth";

export const useGetAppData = (facilityId = 1) => {
    const { authenticated } = useAuth()

    const getFacilityStatus = async (facilityId = 1) => {
        const { data } = await api.get(`/Facility/${facilityId}`)
        return data
    }

    const getFacilityBookings = async (facilityId = 1) => {
        const { data } = await api.get(`/Facility/${facilityId}/bookings`)
        return data?.sort((a, b) => (DateTime.fromISO(a.startTime) - DateTime.fromISO(b.startTime)))

    }

    const getUserBookingsAndSessions = async () => {
        const { data } = await api.get('/Users/sessions')
        return data
    }

    const data = useQueries([
        {
            queryKey: ['facility-status'],
            queryFn: () => getFacilityStatus(facilityId),
            staleTime: 1000 * 60 * 15,
            refetchInterval: 1000 * 60 * 15,
            enabled: authenticated,
        },
        {
            queryKey: ['facility-bookings', facilityId],
            queryFn: () => getFacilityBookings(facilityId),
            staleTime: 1000 * 60,
            refetchInterval: 1000 * 60,
            enabled: authenticated,
        },
        {
            queryKey: ['user-sessions-and-bookings'],
            queryFn: () => getUserBookingsAndSessions(facilityId),
            staleTime: 30000,
            refetchIntervalInBackground: true,
            enabled: authenticated,
        }
    ])

    return data
}