export default function Button({
    children,
    onClick,
    disabled,
    value,
    style
}) {
    return (
        <button
            value={value}
            onClick={onClick}
            disabled={disabled}
            style={{
                backgroundColor: '#2dd67d',
                color: '#ededed',
                fontSize: 14,
                padding: '10px 15px',
                borderRadius: '1rem',
                border: 'none',
                margin: 'auto 0 auto 0',
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
                outline: 'none',
                height: 40,
                minWidth: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: disabled ? .5 : 1,
                ...style,
            }}
        >
            { children }
        </button>
    )
}
